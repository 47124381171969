import { DialogComponent } from "@syncfusion/ej2-react-popups";
import React, {
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer,
  useRef,
} from "react";
import { useRecoilState } from "recoil";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDetailFieldCustom from "../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import RAFDetailsValueWithSeparator from "../../../RAFComponents/Navigation/RAFDetailsValueWithSeparator/RAFDetailsValueWithSeparator";
import { RafClearDialogContent } from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import { getDisplayNameByModuleName } from "../../../RAFComponents/helpers/RAFMenuHelper";
import {
  ConvertSystemName,
  isNotNullAndUndefined,
  isNullOrUndefined,
  joinStringArray,
} from "../../../RAFComponents/helpers/utils";
import { RAFDataType } from "../../../RAFComponents/models/Common/RAFDataType";
import { EntityRow } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import { getEntityByObjectName } from "../../../RAFMaster/helpers/RMutils";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import {
  CarePlanPermissionConstants,
  CareRecipientPermissionConstants,
} from "../../../constants/CareESIO/CareESIOPermissionConstant";
import {
  CareEsioEntity,
  CareEsioTab,
} from "../../../constants/CareESIO/CareEsioConstants";
import {
  BrowserIsDevice,
  MomentFormats,
  RAFButtonConstant,
  RAFRiskRegisterType,
} from "../../../constants/Common/Constants";
import RAFCreate from "../../Common/Create";
import { RAFObjectContext } from "../../Common/Providers/RAFObjectContextProvider";
import RAFEdit from "../../Common/Update";
import CareAssessmentList from "../CareAssessmentLibrary/CareAssessmentList";
import CareGoalList from "../CareGoal/CareGoalList";
import CareInterventionSupportList from "../CareInterventionSupport/CareInterventionSupportList";
import { atomSelectedCarePlanTabItem } from "../CareRecipientHelper";
import MedicationList from "../Medication/MedicationList";
import PreferenceList from "../Preference/PreferenceList";
import RecurringConfigList from "../RecurringConfig/RecurringConfigList";
import HealthConditionList from "../RiskRegister/HealthConditionList";
import RiskRegisterList from "../RiskRegister/RiskRegisterList";
import TriggerList from "../Trigger/TriggerList";
import { getActiveCarePlanByCareRecipient } from "./CarePlanHelper";

interface IProps {
  careRecipientUID?: string;
  careRecipientName?: string;
  careRecipientRow?: any;
  uimode?: "Tab" | "default";
}

interface IState {
  isLoading: boolean;
  carePlanEntity: EntityRow;
  activeCarePlan: any;
  // carePlanSelectedTabItem: {
  //   text: string;
  //   value: string;
  // };
  showManageCarePlanContent: boolean;
}

const headertext = [
  // { text: "Client Overview" },
  {
    text: CareEsioTab.PermanentCondition.CollectionName,
    value: CareEsioTab.PermanentCondition.Value,
  },
  {
    text: CareEsioEntity.CareRecipientMedication.CollectionName,
    value: CareEsioEntity.CareRecipientMedication.EntityName,
  },
  {
    text: CareEsioEntity.CareRecipientPreference.CollectionName,
    value: CareEsioEntity.CareRecipientPreference.EntityName,
  },

  {
    text: CareEsioEntity.CareRecipientTrigger.CollectionName,
    value: CareEsioEntity.CareRecipientTrigger.EntityName,
  },
  {
    text: "Support Needs",
    value: CareEsioEntity.CareAssessment.EntityName,
  },
  {
    text: CareEsioEntity.RiskRegister.CollectionName,
    value: CareEsioEntity.RiskRegister.EntityName,
  },
  {
    text: "Tasks",
    value: CareEsioEntity.CareInterventionSupport.EntityName,
  },
  {
    text: CareEsioEntity.CareGoal.CollectionName,
    value: CareEsioEntity.CareGoal.EntityName,
  },
  {
    text: CareEsioEntity.RecurringConfig.CollectionName,
    value: CareEsioEntity.RecurringConfig.EntityName,
  },
];

function CarePlanDetails({ ...props }: PropsWithChildren<IProps>) {
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: false,
      carePlanEntity: null,
      activeCarePlan: null,
      // carePlanSelectedTabItem: headertext[0],
      showManageCarePlanContent: false,
    }
  );

  const rafObject = React.useContext(RAFObjectContext);
  const currentObject = rafObject.rafObject;

  let carePlanDialogRef = useRef<DialogComponent>(null);
  const carePlanModule = CareEsioEntity.CarePlan.EntityName;
  const [selectedCarePlanTabItem, setselectedCarePlanTabItem] = useRecoilState(
    atomSelectedCarePlanTabItem(CareEsioEntity.CareRecipient.EntityName)
  );

  useEffect(() => {
    refresh();
  }, [props.careRecipientUID]);

  // useEffect(() => {
  //   setselectedCarePlanTabItem(headertext[0]);
  // }, []);

  const carePlanContent = () => {
    return carePlanSectionContent();
  };

  const onSelectCarePlanTab = (e) => {
    setselectedCarePlanTabItem(e);
    // setState({ carePlanSelectedTabItem: e });
  };

  const renderPlanTabContent = () => {
    let selectedItem = selectedCarePlanTabItem.value;
    if (selectedItem === CareEsioTab.All.Value) {
      return (
        <div
          id={`care_recipient_${CareEsioEntity.CarePlan.EntityName}_${selectedItem}`}
        >
          <CareAssessmentList
            activeCarePlan={state.activeCarePlan}
            careRecipientUID={props.careRecipientUID}
            showHeaderTitle={false}
            careRecipientRow={props.careRecipientRow}
            entityName={CareEsioEntity.CarePlan.EntityName}
          />
        </div>
      );
    } else if (selectedItem === CareEsioEntity.CareAssessment.EntityName) {
      return (
        <div
          id={`care_recipient_${CareEsioEntity.CarePlan.EntityName}_${selectedItem}`}
        >
          {CareAssessmentContent()}
        </div>
      );
    } else if (
      selectedItem === CareEsioEntity.CareInterventionSupport.EntityName
    ) {
      return (
        <div
          id={`care_recipient_${CareEsioEntity.CarePlan.EntityName}_${selectedItem}`}
        >
          {CareInterventionSupportContent()}
        </div>
      );
    } else if (selectedItem === CareEsioEntity.RecurringConfig.EntityName) {
      return (
        <div
          id={`care_recipient_${CareEsioEntity.RecurringConfig.EntityName}_${selectedItem}`}
        >
          {recurringConfigContent()}
        </div>
      );
    } else if (selectedItem === CareEsioEntity.RiskRegister.EntityName) {
      return (
        <div
          id={`care_recipient_${CareEsioEntity.CarePlan.EntityName}_${selectedItem}`}
          className="pt-3"
        >
          {CareRiskRegisterContent()}
        </div>
      );
    } else if (selectedItem === CareEsioEntity.CareGoal.EntityName) {
      return (
        <div
          id={`care_recipient_${CareEsioEntity.CarePlan.EntityName}_${selectedItem}`}
        >
          {CareGoalContent()}
        </div>
      );
    } else if (
      selectedItem === CareEsioEntity.CareRecipientPreference.EntityName
    ) {
      return (
        <div
          id={`care_recipient_${CareEsioEntity.CarePlan.EntityName}_${selectedItem}`}
        >
          {CarePreferenceContent()}
        </div>
      );
    } else if (
      selectedItem === CareEsioEntity.CareRecipientTrigger.EntityName
    ) {
      return (
        <div
          id={`care_recipient_${CareEsioEntity.CarePlan.EntityName}_${selectedItem}`}
        >
          {CareTriggerContent()}
        </div>
      );
    } else if (
      selectedItem === CareEsioEntity.CareRecipientMedication.EntityName
    ) {
      return (
        <div
          className="pt-3"
          id={`care_recipient_${CareEsioEntity.CarePlan.EntityName}_${selectedItem}`}
        >
          {CareMedicationContent()}
        </div>
      );
    } else if (selectedItem === CareEsioTab.PermanentCondition.Value) {
      return (
        <div
          id={`care_recipient_${CareEsioEntity.CarePlan.EntityName}_${selectedItem}`}
        >
          {CareHealthConditionContent()}
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const recurringConfigContent = () => {
    return (
      <RecurringConfigList
        relatedToUID={props.careRecipientUID}
        relatedTo={props.careRecipientName}
        relatedToType={CareEsioEntity.CareRecipient.EntityName}
      />
    );
  };

  const CareGoalContent = () => {
    return (
      <div
        // className="p-3"
        className={"pt-3"}
      >
        <CareGoalList
          careRecipientUID={props.careRecipientUID}
          careRecipientName={props.careRecipientName}
          careAssessmentUID={null}
          careAssessmentRow={null}
          uiStyle={"DetailsStyle"}
        />
      </div>
    );
  };

  const CareAssessmentContent = () => {
    return (
      <div
        // className="p-3"
        className={"pt-3"}
      >
        <CareAssessmentList
          careRecipientUID={props.careRecipientUID}
          activeCarePlan={null}
          uiStyle="DetailsStyle"
          careRecipientRow={props.careRecipientRow}
          entityName={CareEsioEntity.CarePlan.EntityName}
        />
      </div>
    );
  };

  const CareHealthConditionContent = () => {
    return (
      <div
      // className="p-3"
      >
        <HealthConditionList
          careRecipientUID={props.careRecipientUID}
          careAssessmentUID={null}
          careAssessmentRow={null}
          careRecipientRow={props.careRecipientRow}
          uiStyle={"DetailsStyle"}
          collapsible={false}
          isCollapsed={true}
          guttersClass="gy-2 g-0"
          paddingClass="p-0"
        />
      </div>
    );
  };

  const CareMedicationContent = () => {
    return (
      <div>
        <MedicationList
          careRecipientUID={props.careRecipientUID}
          uiStyle="DetailsStyle"
          isCollapsed={true}
          paddingClass="p-0"
        />
      </div>
    );
  };

  const CareRiskRegisterContent = () => {
    return (
      <div
      // className="p-3"
      >
        <RiskRegisterList
          careRecipientUID={props.careRecipientUID}
          careRecipientRow={props.careRecipientRow}
          careAssessmentUID={null}
          careAssessmentRow={null}
          // uiStyle="DetailsStyle"
          uiStyle="DetailsStyle"
          paddingClass="p-0"
          riskType={RAFRiskRegisterType.Risk}
        />
      </div>
    );
  };

  const CareTriggerContent = () => {
    return (
      <div className="pt-3">
        <TriggerList
          careRecipientUID={props.careRecipientUID}
          uiStyle="DetailsStyle"
          paddingClass="p-0"
          isCollapsed={true}
        />
      </div>
    );
  };

  const CareInterventionSupportContent = () => {
    return (
      <div
        // className="p-3"
        className={"pt-3"}
      >
        <CareInterventionSupportList
          careRecipientUID={props.careRecipientUID}
          careAssessmentUID={null}
          careAssessmentRow={null}
          uiStyle="DetailsStyle"
          includeHeaderClass={false}
          showEmptyState={true}
        />
      </div>
    );
  };

  const CarePreferenceContent = () => {
    return (
      <div
      // className="p-3"
      >
        <PreferenceList
          careRecipientRow={props.careRecipientRow}
          careRecipientUID={props.careRecipientUID}
          showAddBtn
          paddingClass="p-0 pt-3"
          uiStyle="DetailsStyle"
        />
      </div>
    );
  };

  const renderPlanContent = () => {
    const selectedTabValue = selectedCarePlanTabItem;

    return (
      <div
        className={`p-2 p-md-3 bg-white${
          BrowserIsDevice ? "" : "section__div"
        }`}
      >
        {props.uimode === "Tab" ? (
          <div>
            <div
              id="raf_details_page_tab_header_container"
              className="raf_tab_header hideScrollBar full-oval-tab raf_md flex-wrap"
            >
              <div
                id="raf_details_page_tab_header"
                className="raf_tab_header hideScrollBar full-oval-tab raf_md flex-wrap"
              >
                <div className="row g-2">
                  {isNotNullAndUndefined(headertext) && headertext.length > 0
                    ? headertext.map((item, index) => {
                        return (
                          <div className="col-auto" key={index}>
                            <div
                              className={`ms-0 raf_tab_header_item${
                                (isNotNullAndUndefined(selectedTabValue) &&
                                  selectedTabValue.text === item.text) ||
                                (isNullOrUndefined(selectedTabValue.text) &&
                                  index === 0)
                                  ? " active"
                                  : ""
                              }`}
                              key={item.text}
                              onClick={() => onSelectCarePlanTab(item)}
                            >
                              <span>{item.text}</span>
                            </div>
                          </div>
                        );
                      })
                    : ""}
                </div>
              </div>
            </div>
            {renderPlanTabContent()}
          </div>
        ) : (
          <div className="row gy-3 gx-0">
            <div
              className="col-12"
              id={`care_recipient_${CareEsioEntity.CarePlan.EntityName}_${CareEsioEntity.CareGoal.EntityName}`}
            >
              {CareGoalContent()}
            </div>
            <div
              className="col-12"
              id={`care_recipient_${CareEsioEntity.CarePlan.EntityName}_${CareEsioEntity.CarePlan.EntityName}`}
            >
              {CareAssessmentContent()}
            </div>
            <div
              className="col-12"
              id={`care_recipient_${CareEsioEntity.CarePlan.EntityName}_${CareEsioEntity.RiskRegister.EntityName}_health_condition`}
            >
              {CareHealthConditionContent()}
              {/* <RiskRegisterList
              careRecipientUID={props.careRecipientUID}
              careAssessmentUID={null}
              careAssessmentRow={null}
              // uiStyle="DetailsStyle"
              riskType={RAFRiskRegisterType.HealthCondition}
            /> */}
            </div>
            <div
              className="col-12"
              id={`care_recipient_${CareEsioEntity.CarePlan.EntityName}_${CareEsioEntity.CareRecipientTrigger.EntityName}`}
            >
              {CareMedicationContent()}
            </div>
            <div
              className="col-12"
              id={`care_recipient_${CareEsioEntity.CarePlan.EntityName}_${CareEsioEntity.RiskRegister.EntityName}`}
            >
              {CareRiskRegisterContent()}
            </div>
            <div
              className="col-12"
              id={`care_recipient_${CareEsioEntity.CarePlan.EntityName}_${CareEsioEntity.CareRecipientTrigger.EntityName}`}
            >
              {CareTriggerContent()}
            </div>
            <div
              className="col-12"
              id={`care_recipient_${CareEsioEntity.CarePlan.EntityName}_${CareEsioEntity.CareRecipient.EntityName}`}
            >
              {CareInterventionSupportContent()}
            </div>
            <div
              className="col-12"
              id={`care_recipient_${CareEsioEntity.CarePlan.EntityName}_${CareEsioEntity.CareRecipientPreference.EntityName}`}
            >
              {CarePreferenceContent()}
            </div>
          </div>
        )}
      </div>
    );
  };

  //manage carePlanContent start

  const refresh = async () => {
    await RafClearDialogContent(carePlanDialogRef);
    if (isNotNullAndUndefined(props.careRecipientUID)) {
      setState({ isLoading: true, showManageCarePlanContent: false });
      const carePlanEntity = await getEntityByObjectName({
        ObjectName: ConvertSystemName(carePlanModule),
      });
      const activeCarePlan = await getActiveCarePlanByCareRecipient(
        props.careRecipientUID,
        isNotNullAndUndefined(carePlanEntity) ? carePlanEntity.UID : null
      );
      setState({ isLoading: false, activeCarePlan, carePlanEntity });
    }
  };

  const onClickManageCarePlan = () => {
    setState({ showManageCarePlanContent: true });
  };

  const refreshOnManageCare = () => {
    refresh();
  };

  function manageCarePlanContent() {
    if (state.showManageCarePlanContent === true) {
      if (isNotNullAndUndefined(state.activeCarePlan)) {
        return (
          <RAFEdit
            objectUID={state.activeCarePlan && state.activeCarePlan.UID}
            onSave={refreshOnManageCare.bind(this)}
            onClose={closeManageCareDialog.bind(this)}
            //onDelete={onClickDeleteCarePlan.bind(this)}
            moduleName={carePlanModule}
            isActive
            {...props}
          />
        );
      } else {
        let initialValues = {};
        if (isNotNullAndUndefined(currentObject)) {
          const carePlanTitle =
            currentObject.recipient_name ??
            joinStringArray([
              currentObject.first_name,
              currentObject.middle_name,
              currentObject.last_name,
            ]);
          initialValues[
            "Title"
          ] = `${carePlanTitle}'s ${CareEsioEntity.CarePlan.DisplayName}`;
          initialValues["CareRecipientUID"] = currentObject.UID;
          initialValues["CareRecipient"] = carePlanTitle;
        } else {
          initialValues[
            "Title"
          ] = `${props.careRecipientName}'s ${CareEsioEntity.CarePlan.DisplayName}`;
          initialValues["CareRecipientUID"] = props.careRecipientUID;
          initialValues["CareRecipient"] = props.careRecipientName;
        }

        return (
          <RAFCreate
            initialValues={initialValues}
            onSave={refreshOnManageCare.bind(this)}
            onClose={closeManageCareDialog.bind(this)}
            isActive
            moduleName={carePlanModule}
            {...props}
          />
        );
      }
    } else {
      return <div></div>;
    }
  }

  const closeManageCareDialog = async () => {
    await RafClearDialogContent(carePlanDialogRef);
    setState({ showManageCarePlanContent: false });
  };

  const carePlanCustomTitle = () => {
    return (
      <div className="d-flex align-items-center">
        {isNotNullAndUndefined(state.activeCarePlan) && (
          <>
            <RAFDetailsValueWithSeparator>
              <RAFDetailFieldCustom
                title="Review Date :"
                value={state.activeCarePlan["ReviewDate"]}
                rowClassName="gx-2 align-items-baseline"
                labelDivClassName="col-auto"
                valueDivClassName="col"
                labelClassName={BrowserIsDevice ? "body_3" : "body_2"}
                valueClassName={
                  BrowserIsDevice ? "subtitle_3" : "subtitle_2 semi_bold"
                }
                fieldFormat={{
                  type: RAFDataType.Date,
                  format: MomentFormats.DATE,
                }}
              />
              {/* )} */}
            </RAFDetailsValueWithSeparator>
            {createCarePlanSectionBtn()}
          </>
        )}
      </div>
    );
  };

  const createCarePlanSectionBtn = () => {
    return (
      <div className="ms-2" id="divHeaderButtons">
        <RAFPermissionRender
          permissionName={
            CareRecipientPermissionConstants.CareRecipientManageCarePlan
          }
        >
          <div className="">
            <RAFButtonComponent
              type="button"
              className="custom-button-sm btn_brand_primary transparent"
              action={RAFButtonConstant.Edit}
              onClick={() => onClickManageCarePlan()}
              iconBtn
              idString={`${
                state.activeCarePlan && state.activeCarePlan.UID
              }__responsive`}
            />
          </div>
        </RAFPermissionRender>
      </div>
    );
  };

  //manage carePlanContent end

  const carePlanSectionContent = () => {
    let recipientFirstName = isNotNullAndUndefined(currentObject)
      ? currentObject["first_name"]
      : props.careRecipientRow["first_name"];
    return (
      <div>
        <div className="p-2 p-md-3 py-md-2">
          <div className="row gx-2 align-items-center">
            <div className="col-12">
              <div className="row gx-2 align-items-center">
                <div className="col">
                  <div style={{ marginBottom: "2px" }}>
                    <span className="header_5">{`${recipientFirstName}'s Care Summary`}</span>
                  </div>
                </div>
                <div className="col-auto"></div>
              </div>
            </div>
            <div className="col-12">
              <RAFPermissionRender
                permissionName={CarePlanPermissionConstants.CarePlanRead}
              >
                <div className="">
                  {state.isLoading === false ? (
                    <>
                      {carePlanCustomTitle()}
                      {state.showManageCarePlanContent && (
                        <DialogComponent
                          header={`${
                            isNotNullAndUndefined(state.activeCarePlan)
                              ? "Update"
                              : "Add"
                          } ${getDisplayNameByModuleName(
                            carePlanModule,
                            state.carePlanEntity.DisplayName
                          )}`}
                          showCloseIcon
                          visible={state.showManageCarePlanContent}
                          content={manageCarePlanContent.bind(this)}
                          //cssClass={entity.EntityType === RAFEntityType.UserForm ? "dlg-new-style centerDialog-lg createEditForm form-center-dialog" : "rightDialog createEditForm full-height form-center-dialog"}
                          cssClass={
                            "rightDialog createEditForm full-height form-center-dialog dlg-new-style"
                          }
                          isModal
                          target="body"
                          closeOnEscape={false}
                          close={closeManageCareDialog.bind(this)}
                          id={`dlg_create_${carePlanModule}`}
                          zIndex={1200}
                          open={PreventFocusOnDialogOpen}
                          ref={carePlanDialogRef}
                        ></DialogComponent>
                      )}
                    </>
                  ) : (
                    <div className="container-fluid px-0">
                      <ACLoadingPanel loadingText="Preparing Data..." />
                    </div>
                  )}
                </div>
              </RAFPermissionRender>
            </div>
          </div>
        </div>
        <div className="custom__card__separator custom__card__separator__light"></div>
        <div className={BrowserIsDevice ? "" : "section__container"}>
          {renderPlanContent()}
        </div>
      </div>
    );
  };

  const carePlanSectionContentOld = () => {
    return (
      <div className={BrowserIsDevice ? "" : "section__container"}>
        {renderPlanContent()}
      </div>
    );
  };

  if (isNotNullAndUndefined(props.careRecipientUID)) {
    return (
      <RAFPermissionRender
        permissionName={CarePlanPermissionConstants.CarePlanRead}
      >
        <div className="h-100">
          {state.isLoading === false ? (
            carePlanContent()
          ) : (
            <div className="container-fluid px-0">
              <ACLoadingPanel loadingText="Preparing Data..." />
            </div>
          )}
        </div>
      </RAFPermissionRender>
    );
  } else {
    return <div></div>;
  }
}

export default React.memo(CarePlanDetails);

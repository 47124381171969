import { DialogUtility } from "@syncfusion/ej2-popups";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import React, {
  PropsWithChildren,
  Reducer,
  useCallback,
  useReducer,
} from "react";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  getRelatedRecords,
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
  RAFEventName,
  triggerRAFEvent,
} from "../../../RAFComponents/helpers/RAFDocumentEvents";
import {
  isNotEmptyArray,
  isNotNullAndUndefined,
  propertyOf,
} from "../../../RAFComponents/helpers/utils";
import { EntityRow } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import { CareRecipientPermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { getServiceTransactionCollectionNameFromStorage } from "../../ActiveContacts/ServiceTransaction/ServiceTransactionHelper";
import { ServiceTransactionRow } from "../../ActiveContacts/ServiceTransaction/ServiceTransactionRow";
import { DeleteServiceContractItemRecord } from "./CareServiceContractItemHelper";
import CareServiceContractItemListContent from "./CareServiceContractItemListContent";
import UpdateCareServiceContractItem from "./UpdateCareServiceContractItem";

interface IProps {
  careRecipientUID: string;
  serviceContractUID: string;
  serviceContractId: string;
  careServiceContractItemEntity: EntityRow;
}

interface IState {
  outerDivKey: number;
  selectedObjectUID: string;
  showUpdateServiceContractItem: boolean;
}

function CareServiceContractItemList({ ...props }: PropsWithChildren<IProps>) {
  let deleteDialog: any;
  const careServiceContractItemModule =
    CareEsioEntity.CareServiceContractItem.EntityName;
  const serviceTransactionCollectionName =
    getServiceTransactionCollectionNameFromStorage();

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      outerDivKey: Math.random(),
      selectedObjectUID: null,
      showUpdateServiceContractItem: false,
    }
  );

  //UpdateServiceContract start
  const rowClick = useCallback((rowData: any) => {
    if (isNotNullAndUndefined(rowData) && isNotNullAndUndefined(rowData.UID)) {
      setState({
        showUpdateServiceContractItem: true,
        selectedObjectUID: rowData.UID,
      });
    }
  }, []);

  const updateServiceContractItemContent = () => {
    if (state.showUpdateServiceContractItem) {
      // return (<RAFEdit
      //     objectUID={state.selectedObjectUID}
      //     moduleName={careServiceContractItemModule}
      //     onSave={refreshOnUpdate.bind(this)}
      //     onClose={closeUpdateServiceContractItemDialog.bind(this)}
      //     onDelete={onClickDeleteSection}
      //     isActive
      //     {...props}
      // />)
      return (
        <UpdateCareServiceContractItem
          objectUID={state.selectedObjectUID}
          moduleName={careServiceContractItemModule}
          onSave={refreshOnUpdate.bind(this)}
          onClose={closeUpdateServiceContractItemDialog.bind(this)}
          onDelete={onClickDeleteContractItem.bind(this)}
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  };

  const refreshOnUpdate = () => {
    const eventKey = `${RAFEventName.ReloadRelatedItemsContent}_${careServiceContractItemModule}_${props.careRecipientUID}`;
    triggerRAFEvent(eventKey, {
      careRecipientUID: `${props.careRecipientUID}`,
    });
    setState({
      showUpdateServiceContractItem: false,
      outerDivKey: Math.random(),
    });
  };

  const closeUpdateServiceContractItemDialog = () => {
    setState({ showUpdateServiceContractItem: false });
  };
  //UpdateServiceContract end

  //delete item start
  const onClickDeleteContractItem = () => {
    deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: { text: "Yes", click: deleteRecord.bind(this) },
      title: `Delete ${CareEsioEntity.CareServiceContractItem.DisplayName}`,
      position: { X: "center", Y: "center" },
      cssClass: `alert-dialog deleteDialog`,
    });
  };

  const isChildeItemExist = () => {
    return new Promise<Boolean>(async (resolve) => {
      const serviceTransactionModule = RAFEntityName.ServiceTransaction;
      const relatedItems = await getRelatedRecords(
        serviceTransactionModule,
        null,
        propertyOf<ServiceTransactionRow>("ServiceContractItemUID"),
        state.selectedObjectUID,
        null,
        null, //serviceTransactionModule,
        null,
        null,
        null,
        1,
        0
        //`related_${serviceTransactionModule}`
      );
      if (isNotEmptyArray(relatedItems)) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  async function deleteRecord() {
    let progressDiv = showProgress(".deleteDialog.e-dialog");
    //check is child item exist
    const isChildeExist = await isChildeItemExist();
    if (isChildeExist) {
      showWarningToast(
        `Apologies, you cannot delete this contract item as it is associated with one or more ${serviceTransactionCollectionName}.`
      );
      hideProgress(progressDiv);
      deleteDialog.hide();
      return;
    }

    let isDeleted = await DeleteServiceContractItemRecord(
      state.selectedObjectUID
    );

    if (isDeleted) {
      hideProgress(progressDiv);
      deleteDialog.hide();
      refreshOnUpdate();
    } else {
      hideProgress(progressDiv);
      deleteDialog.hide();
    }
  }
  //delete item end

  return (
    <RAFPermissionRender
      permissionName={
        CareRecipientPermissionConstants.CareRecipientViewServiceContractItems
      }
    >
      <div>
        <CareServiceContractItemListContent
          key={state.outerDivKey}
          serviceContractId={props.serviceContractUID}
          serviceContractUID={props.serviceContractUID}
          rowClick={rowClick}
        />
        {state.showUpdateServiceContractItem && (
          <DialogComponent
            header={`Update ${CareEsioEntity.CareServiceContractItem.DisplayName}`}
            showCloseIcon
            visible={state.showUpdateServiceContractItem}
            cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
            id={`dlg_update_${careServiceContractItemModule}`}
            content={updateServiceContractItemContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={closeUpdateServiceContractItemDialog.bind(this)}
            zIndex={1200}
            open={PreventFocusOnDialogOpen}
          ></DialogComponent>
        )}
      </div>
    </RAFPermissionRender>
  );
}

export default React.memo(CareServiceContractItemList);

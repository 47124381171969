import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import React, {
  PropsWithChildren,
  Reducer,
  useCallback,
  useEffect,
  useReducer,
} from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import CustomCardWidget from "../../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDetailFieldCustom from "../../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import RAFAttributeRelatedListProvider from "../../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import RAFPermissionRender from "../../../../RAFComponents/helpers/PermissionHelper";
import RAFTagDetails from "../../../../RAFComponents/helpers/RAFTagDetails";
import {
  IsNotNullOrWhiteSpace,
  calculateAge,
  getDate,
  isNotNullAndUndefined,
  isNullOrUndefined,
  joinStringArray,
} from "../../../../RAFComponents/helpers/utils";
import { CareRecipientPermissionConstants } from "../../../../constants/CareESIO/CareESIOPermissionConstant";
import {
  CareEsioEntity,
  RAFCareRecipientCurrentStatus,
} from "../../../../constants/CareESIO/CareEsioConstants";
import {
  BrowserIsDevice,
  MomentFormats,
  RAFButtonConstant,
  RAFRiskRegisterType,
} from "../../../../constants/Common/Constants";
import UserProfilePhoto from "../../../ActiveContacts/User/UserProfilePhoto";
import RAFFollowButton from "../../../Common/Details/RAFFollowButton";
import SummarySectionFields from "../../../Common/Details/SummarySectionFields";
import RAFEntityProvider from "../../../Common/Providers/RAFEntityProvider";
import { RAFObjectContext } from "../../../Common/Providers/RAFObjectContextProvider";
import { atomSelectedRecipientRightContent } from "../../CareRecipientHelper";
import EmployeeContactUserLogIn from "../../Employee/EmployeeUser/EmployeeContactUserLogIn";
import HealthConditionList from "../../RiskRegister/HealthConditionList";
import RiskRegisterList from "../../RiskRegister/RiskRegisterList";
import TriggerList from "../../Trigger/TriggerList";
import AboutClient from "./AboutClient";

interface IProps {
  careRecipientUID?: string;
  selectedTabItem?: {
    text: string;
    value: string;
  };
  isdashboard?: boolean;
  cardStyle?:
  | "SimpleCard"
  | "SimpleContent"
  | "PhoneEmailAddressContent"
  | "default";
  selectedRecipientRightContent?: string;
  recipientObject?: any;
}

interface IState {
  showMoreClientDetailsContent: boolean;
}

function CareRecipientProfileInformation({
  ...props
}: PropsWithChildren<IProps>) {
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      showMoreClientDetailsContent: false,
    }
  );

  const rafObject = React.useContext(RAFObjectContext);
  const currentObject: any = isNotNullAndUndefined(props.recipientObject)
    ? props.recipientObject
    : rafObject.rafObject;

  const hasPermissionToEditTags = (isNotNullAndUndefined(rafObject) &&
    isNotNullAndUndefined(rafObject.getObject)) ? true : false;

  //const [toggleVisiblity, setToggleVisiblity] = React.useState("hide");
  let careRecipientProfileInfoDiv = document.getElementById(
    "careRecipientProfileInfoDiv"
  );

  let navigate = useNavigate();

  useEffect(() => {
    if (IsNotNullOrWhiteSpace(careRecipientProfileInfoDiv)) {
      careRecipientProfileInfoDiv.classList.remove("overflow-hidden");
    }
  }, [props.selectedTabItem.value, careRecipientProfileInfoDiv]);
  const careRecipientName = isNotNullAndUndefined(currentObject)
    ? isNotNullAndUndefined(currentObject.recipient_name)
      ? currentObject.recipient_name
      : joinStringArray([
        currentObject.first_name,
        currentObject.middle_name,
        currentObject.last_name,
      ])
    : "";

  const setSelectedRecipientRightContent = useSetRecoilState(
    atomSelectedRecipientRightContent(CareEsioEntity.CareRecipient.EntityName)
  );

  let dOBDate = isNotNullAndUndefined(currentObject) && currentObject.dob;
  // if (isNotNullAndUndefined(dOBDate)) {
  //   dOBDate = convertUTCDateToLocalTimezone(dOBDate);
  //   dOBDate = moment(dOBDate).format("DD/MM/YYYY");
  // }
  const profileCardPhoneEmailAddressContent = () => {
    let address = currentObject["address"];
    if (isNullOrUndefined(address)) {
      address = {
        line1: null,
        city: null,
        state: null,
        country: null,
        postal_code: null,
      };
    }

    const refreshOnUpdateTag = () => {
      rafObject.refresh(rafObject.objectId, null);
    };

    return (
      <>
        <div className="col-12 d-flex align-items-center">
          <span className="fas fa-tag me-1 fa-sm"></span>
          <span className="profile_card_details_label">
            Tags
          </span>
        </div>
        <div className="col-12 mt-2">
          <RAFTagDetails
            initialTags={isNotNullAndUndefined(currentObject) ? currentObject.tags_list_json : null}
            relatedToUID={isNotNullAndUndefined(currentObject) ? currentObject.UID : null}
            moduleName={CareEsioEntity.CareRecipient.EntityName}
            onSave={refreshOnUpdateTag}
            detailsPanel
            // label="Tags"
            defaultFieldName="tags_list_json"
            disabled={!hasPermissionToEditTags}
          />
        </div>

        <div className="col-12">
          <div className="custom__card__separator custom__card__separator__light"></div>
        </div>
        <div className="col-12">
          <span className="profile_card_details_label mb-2">
            CONTACT INFORMATION
          </span>
        </div>
        <div className="col-12 mt-2">
          {/* <RAFDetailsValueWithSeparator outerClassName="h-auto"> */}
          <RAFDetailFieldCustom
            value={currentObject.primary_phone ?? currentObject.other_phone}
            title="Phone number"
            // colClassName="col-12"
            labelIconName="fas fa-phone fa-sm-normal text_orange"
            labelDivClassName="col-auto"
            valueDivClassName="col"
            rowClassName="gx-2 align-items-center"
            valueClassName="ecllipseFirstLine details-value"
          />
        </div>
        <div className="col-12 mt-2">
          <RAFDetailFieldCustom
            value={currentObject.email}
            title="Email"
            // colClassName="col-12"
            labelIconName="fas fa-envelope fa-sm-normal text_orange"
            labelDivClassName="col-auto"
            valueDivClassName="col"
            rowClassName="gx-2 align-items-center"
            valueClassName="ecllipseFirstLine details-value"
          />
          {/* </RAFDetailsValueWithSeparator> */}
        </div>
        <div className="col-12">
          <div className="custom__card__separator custom__card__separator__light"></div>
        </div>
        {isNotNullAndUndefined(address) && (
          <>
            <div className="col-12">
              <div className="row g-2">
                <div className="col">
                  <span className="profile_card_details_label mb-2">
                    ADDRESS INFORMATION
                  </span>
                </div>
                {IsNotNullOrWhiteSpace(currentObject.address1) && (
                  <div className="col-auto">
                    <RAFButtonComponent
                      isPrimary
                      btnContent="Directions"
                      iconCss="fas fa-diamond-turn-right fa-md-noraml"
                      className={`e-round e-outline custom_button_sm`}
                      iconBtn
                      enableToolTip
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="col-12 mt-2">
              <div className="d-flex align-self-center flex-column">
                <div className="m-0 details-value">
                  {IsNotNullOrWhiteSpace(address["line1"])
                    ? address["line1"]
                    : "Not set"}
                </div>
                <div className="d-flex">
                  {IsNotNullOrWhiteSpace(address["city"]) ? (
                    <div className="m-0 details-value">{address["city"]} </div>
                  ) : null}
                  {IsNotNullOrWhiteSpace(address["state"]) ? (
                    <div className="m-0 details-value">
                      {" "}
                      , {address["state"]}
                    </div>
                  ) : null}
                </div>
                <div className="d-flex">
                  {IsNotNullOrWhiteSpace(address["country"]) ? (
                    <div className="m-0 details-value">
                      {address["country"]}
                    </div>
                  ) : null}
                  {IsNotNullOrWhiteSpace(address["postal_code"]) ? (
                    <div className="m-0 details-value">
                      - {address["postal_code"]}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </>
        )}
        <EmployeeContactUserLogIn
          relatedTo={currentObject && currentObject.recipient_name}
          relatedToUID={currentObject && currentObject.UID}
          relatedToType={CareEsioEntity.CareRecipient.EntityName}
          firstName={currentObject && currentObject.first_name}
          lastName={currentObject && currentObject.last_name}
          displayMode="default"
          permissionNameManageLogin={CareRecipientPermissionConstants.CareRecipient_ManageLogin}
        />
        <div className="col-12">
          <div className="custom__card__separator custom__card__separator__light"></div>
        </div>
        <HealthConditionList
          careRecipientUID={props.careRecipientUID}
          careAssessmentUID={null}
          careAssessmentRow={null}
          uiStyle={"ProfileSummaryStyle"}
          showAddBtn={false}
          guttersClass="gy-2 g-0"
          cardClassName="care_recipient_care_plan_item"
        />
        <div className="col-12">
          <div className="custom__card__separator custom__card__separator__light"></div>
        </div>
        <RiskRegisterList
          careRecipientUID={props.careRecipientUID}
          careAssessmentUID={null}
          careAssessmentRow={null}
          uiStyle={"ProfileSummaryStyle"}
          riskType={RAFRiskRegisterType.Risk}
          // paddingClass="p-0"
          showAddBtn={false}
          guttersClass="gy-2"
          cardClassName="care_recipient_care_plan_item"
        />
        <div className="col-12">
          <div className="custom__card__separator custom__card__separator__light"></div>
        </div>
        <TriggerList
          careRecipientUID={props.careRecipientUID}
          uiStyle={"ProfileSummaryStyle"}
          // paddingClass="p-0"
          showAddBtn={false}
        />
      </>
    );
  };

  const NDISInformationContent = () => {
    let ndisNumber = (
      <div className="col-12 details-value mt-1">
        <span className="details-value">
          {isNotNullAndUndefined(currentObject) && currentObject.ndia_number
            ? currentObject.ndia_number.replace(/./g, "*")
            : "Not set"}
          {/* {isNotNullAndUndefined(currentObject) && currentObject.ndia_number
            ? currentObject.ndia_number
            : "Not set"} */}
        </span>
      </div>
    );
    let ndisNumberLabel = (
      <div className="col-12 profile_card_details_label">
        <span className="profile_card_details_label">NDIS Number</span>
      </div>
    );
    let ndisFindingType = (
      <div className="col-12 details-value mt-1">
        <span className="details-value">
          {isNotNullAndUndefined(currentObject) && currentObject.client_type
            ? currentObject.client_type
            : "Not set"}
        </span>
      </div>
    );
    let ndisFindingTypeLabel = (
      <div className="col-12 profile_card_details_label">
        <span className="profile_card_details_label">NDIS Funding Type</span>
      </div>
    );
    let serviceEndDate = (
      <div className="col-12 details-value mt-1">
        <span className="details-value">
          {isNotNullAndUndefined(currentObject) && currentObject.service_end
            ? getDate(currentObject.service_end, MomentFormats.DATE)
            : "Not set"}
        </span>
      </div>
    );
    let serviceEndDateLabel = (
      <div className="col-12 profile_card_details_label">
        <span className="profile_card_details_label">Service Expiry date</span>
      </div>
    );
    return (
      <>
        {isNotNullAndUndefined(currentObject) && currentObject.ndia_number ? (
          <>
            <div className="col-12">
              <div className="custom__card__separator custom__card__separator__light"></div>
            </div>
            {ndisNumberLabel}
            {ndisNumber}
          </>
        ) : (
          ""
        )}
        {isNotNullAndUndefined(currentObject) && currentObject.client_type ? (
          <>
            <div className="col-12">
              <div className="custom__card__separator custom__card__separator__light"></div>
            </div>
            {ndisFindingTypeLabel}
            {ndisFindingType}
          </>
        ) : (
          ""
        )}
        {isNotNullAndUndefined(currentObject) && currentObject.service_end ? (
          <>
            <div className="col-12">
              <div className="custom__card__separator custom__card__separator__light"></div>
            </div>
            {serviceEndDateLabel}
            {serviceEndDate}
          </>
        ) : (
          ""
        )}
      </>
    );
  };
  const profileCardOverviewContent = () => {
    return (
      <>
        {profileCardPhoneEmailAddressContent()}
        <RAFPermissionRender
          permissionName={CareRecipientPermissionConstants.CareRecipientManage}
        >
          {NDISInformationContent()}
        </RAFPermissionRender>
        {/* <div className="col-12">
          <RAFButtonComponent
            isPrimary
            //action={RAFButtonConstant.ShowMore}
            btnContent="View Client Details"
            onClick={() =>
              onSelectedRecipientRightContent(
                CareEsioEntity.CareRecipient.EntityName
              )
            }
            className={`e-outline w-100`}
          ></RAFButtonComponent>
        </div> */}
        {/* <RAFPermissionRender
          permissionName={
            CareRecipientPermissionConstants.CareRecipientViewServiceAgreements
          }
        >
          <CareRecipientServiceContractItemSummary
            careRecipientUID={props.careRecipientUID}
          />
        </RAFPermissionRender>
        <RAFPermissionRender
          permissionName={
            CareRecipientPermissionConstants.CareRecipientViewDocuments
          }
        >
          <DocumentSectionButtonContent
            relatedToUID={props.careRecipientUID}
            mode="buttonView"
            relatedToType={CareEsioEntity.CareRecipient.EntityName}
          />
        </RAFPermissionRender> */}
      </>
    );
  };

  const alertsAndWarningsContent = () => {
    return (
      <div style={{ cursor: "pointer" }}>
        <div className="col-12 mb-1">
          <span className="subtitle_3 mb-2">ALERTS & WARNINGS</span>
        </div>
        <CustomCardWidget cardClassName="light_border overflow-hidden">
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert severity="success">4 Shifts Invoiced.</Alert>
            <Alert severity="info">2 Approvals pending.</Alert>
            <Alert severity="warning">Funds is low.</Alert>
            <Alert severity="error">Document expiry this week.</Alert>
          </Stack>
        </CustomCardWidget>
      </div>
    );
  };

  const profilePicContent = (profileImgCss, canEdit, iconSize, fontSize) => {
    return (
      <UserProfilePhoto
        uid={
          isNotNullAndUndefined(currentObject) &&
            IsNotNullOrWhiteSpace(currentObject.UID)
            ? currentObject.UID
            : IsNotNullOrWhiteSpace(props.careRecipientUID)
              ? props.careRecipientUID
              : ""
        }
        relatedToType={CareEsioEntity.CareRecipient.EntityName}
        canEdit={canEdit}
        //onChange={() => onChanageProfilePhoto()}
        // profileImgCss={"profileImg-container-xmd"}
        profileImgCss={profileImgCss}
        title={careRecipientName}
        {...(!canEdit && {
          emptyAvatarType: "Initial",
          iconSize: iconSize,
          fontSize: fontSize,
        })}
      // emptyAvatarType="Initial"
      // iconSize={iconSize ?? '7.5rem'}
      // fontSize={fontSize ?? '2rem'}
      />
    );
  };

  const clientTypeBadge = () => {
    if (currentObject.type) {
      return (
        <div className="raf_badge raf_badge_primary raf_sm border-0">
          <div className="d-flex align-items-center">
            <div className="raf_badge_icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
              >
                <path
                  d="M8.60667 7.24671C8.54 7.24004 8.46 7.24004 8.38667 7.24671C6.8 7.19337 5.54 5.89337 5.54 4.29337C5.54 2.66004 6.86 1.33337 8.5 1.33337C10.1333 1.33337 11.46 2.66004 11.46 4.29337C11.4533 5.89337 10.1933 7.19337 8.60667 7.24671Z"
                  stroke="#378DF2"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.27333 9.70663C3.66 10.7866 3.66 12.5466 5.27333 13.62C7.10666 14.8466 10.1133 14.8466 11.9467 13.62C13.56 12.54 13.56 10.78 11.9467 9.70663C10.12 8.48663 7.11333 8.48663 5.27333 9.70663Z"
                  stroke="#378DF2"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="raf_badge_text">
              <span className="raf_badge_text">{currentObject.type}</span>
            </div>
          </div>
        </div>
      );
    }
  };
  const clientStatusBadge = (badgeClass) => {
    let currentStatusDisplayName =
      isNotNullAndUndefined(currentObject) &&
        isNotNullAndUndefined(
          RAFCareRecipientCurrentStatus[currentObject["current_status"]]
        )
        ? RAFCareRecipientCurrentStatus[currentObject.current_status]
          .DisplayName
        : isNotNullAndUndefined(currentObject)
          ? currentObject.current_status
          : "";
    if (isNotNullAndUndefined(currentStatusDisplayName)) {
      return (
        <div
          className={`raf_badge border-0 ${badgeClass} raf_badge_${isNotNullAndUndefined(currentObject)
            ? currentObject.current_status
            : ""
            }`}
        >
          <div className="d-flex align-items-center">
            <div className="raf_badge_icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
              >
                <path
                  d="M2.13333 9.97998C2.84666 12.2733 4.76667 14.04 7.15334 14.5266"
                  stroke="#1DB43E"
                  strokeWidth="1.2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.86667 7.32004C2.20667 3.95337 5.04667 1.33337 8.5 1.33337C11.9533 1.33337 14.7933 3.96004 15.1333 7.32004"
                  stroke="#1DB43E"
                  strokeWidth="1.2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.84 14.5333C12.22 14.0466 14.1333 12.3 14.86 10.0133"
                  stroke="#1DB43E"
                  strokeWidth="1.2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="raf_badge_text">
              <span className="raf_badge_text">{currentStatusDisplayName}</span>
            </div>
          </div>
        </div>
      );
    }
  };
  const clientDOBBadge = (badgeClass) => {
    if (isNotNullAndUndefined(calculateAge(dOBDate, true))) {
      return (
        <div className={`raf_badge raf_badge_secondary border-0 ${badgeClass}`}>
          <div className="d-flex align-items-center">
            <div className="raf_badge_icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
              >
                <path
                  d="M5.83333 1.33337V3.33337"
                  stroke="#F65C06"
                  strokeWidth="1.2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.1667 1.33337V3.33337"
                  stroke="#F65C06"
                  strokeWidth="1.2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.83333 6.06006H14.1667"
                  stroke="#F65C06"
                  strokeWidth="1.2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.5 5.66671V11.3334C14.5 13.3334 13.5 14.6667 11.1667 14.6667H5.83333C3.5 14.6667 2.5 13.3334 2.5 11.3334V5.66671C2.5 3.66671 3.5 2.33337 5.83333 2.33337H11.1667C13.5 2.33337 14.5 3.66671 14.5 5.66671Z"
                  stroke="#F65C06"
                  strokeWidth="1.2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.49699 9.13338H8.50298"
                  stroke="#F65C06"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.02954 9.13338H6.03553"
                  stroke="#F65C06"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.02954 11.1334H6.03553"
                  stroke="#F65C06"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="raf_badge_text">
              <span className="raf_badge_text">
                {calculateAge(dOBDate, true)}
              </span>
            </div>
          </div>
        </div>
      );
    }
  };

  const profileCard = () => {
    return (
      <div className={""}>
        <div className={"d-flex justify-content-center position-relative"}>
          {/* <img
                    src={avatarImage}
                    alt="Avatar Image"
                    className="avatarLogoImage"
                />
                 */}
          {profilePicContent(
            "profileImg-container-xxxl",
            true,
            "7.5rem",
            "2rem"
          )}
        </div>
        <div className="mt-2 pt-1">
          <div className="row g-1 flex-column align-items-center">
            <div className="col-auto">
              <div className="profile_card_header_div">
                <div className="header_5 profile_card_header_div_text">
                  <span className="ecllipseFirstLine word-break-all">
                    {careRecipientName}
                  </span>
                </div>
                <div className="profile_card_header_div_button">
                  <RAFPermissionRender
                    permissionName={
                      CareRecipientPermissionConstants.CareRecipientManage
                    }
                  >
                    <RAFButtonComponent
                      action={RAFButtonConstant.Edit}
                      iconBtn
                      className="btn_brand_primary e-round ms-2"
                      enableToolTip={false}
                      onClick={() =>
                        navigate(
                          `/RAF/Update/${CareEsioEntity.CareRecipient.EntityName}/${rafObject.objectId}`
                        )
                      }
                    ></RAFButtonComponent>
                  </RAFPermissionRender>
                </div>
              </div>
            </div>
            <div className="col-auto">
              <div className="row gx-2">
                {IsNotNullOrWhiteSpace(currentObject.type) && (
                  <div className="col-auto">{clientTypeBadge()}</div>
                )}
                {IsNotNullOrWhiteSpace(currentObject.current_status) && (
                  <div className="col-auto">{clientStatusBadge("raf_sm")}</div>
                )}
                {isNotNullAndUndefined(dOBDate) && (
                  <div className="col-auto">{clientDOBBadge("raf_sm")}</div>
                )}
              </div>
              {/* <div className="secondary-header-text-dark">
                <span>{dOBDate}</span>
                {/* <span>07-08-1985(38 Years)</span> */}
              {/* </div> */}
            </div>
            {props.isdashboard ? (
              <div className={BrowserIsDevice ? "pt-3" : "px-3 pt-4"}>
                <div className="d-flex align-items-center">
                  {/* <RAFButtonComponent
                  btnContent={`View profile`}
                  isPrimary
                  className="w-100 me-2 e-outline"
                  onClick={() =>
                    setSelectedRecipientRightContent(
                      CareEsioEntity.CareRecipient.EntityName
                    )
                  }
                ></RAFButtonComponent> */}

                  <RAFButtonComponent
                    btnContent={`Go to client page`}
                    isPrimary
                    className="w-100 e-outline"
                    onClick={() =>
                      navigate(
                        `/RAF/View/care_recipient/${props.careRecipientUID}`
                      )
                    }
                  ></RAFButtonComponent>
                </div>
              </div>
            ) : (
              ""
            )}
            {/* {props.isdashboard ?
              <div className="col-12 mt-3">
                <RAFButtonComponent btnContent="Go To Client Details" isPrimary uiMode="FooterOutlineBtn"
                  onClick={() => navigate(`/RAF/View/care_recipient/${currentObject.UID}`)}
                ></RAFButtonComponent>
              </div>
              :
              ''
            } */}
          </div>
        </div>
        <div className="custom__card__separator custom__card__separator__light mt-3"></div>
        {false && BrowserIsDevice ? (
          state.showMoreClientDetailsContent ? (
            <div>
              <div
                className="pt-3 d-flex align-items-center justify-content-between"
                onClick={closeMoreClientDetailsDialog}
              >
                <div className="subtitle_3 content_brand_primary_base">
                  Collapse less
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="7"
                    viewBox="0 0 14 7"
                    fill="none"
                    className="rotate"
                  >
                    <path
                      d="M12.2797 0.966797L7.93306 5.31346C7.41973 5.8268 6.57973 5.8268 6.06639 5.31346L1.71973 0.966797"
                      stroke="var(--content-brand-primary-base)"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {/* <i className="subtitle_3 fas fa-chevron-up content_brand_primary_base"></i> */}
                </div>
              </div>
              <div className="custom__card__separator custom__card__separator__light mt-3"></div>
            </div>
          ) : (
            <div
              className="pt-3 d-flex align-items-center justify-content-between"
              onClick={showMoreClientDetailsContent}
            >
              <div className="subtitle_3 content_brand_primary_base">
                Expand more
              </div>
              <div>
                {/* <i className="fas fa-chevron-down content_brand_primary_base"></i> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="7"
                  viewBox="0 0 14 7"
                  fill="none"
                >
                  <path
                    d="M12.2797 0.966797L7.93306 5.31346C7.41973 5.8268 6.57973 5.8268 6.06639 5.31346L1.71973 0.966797"
                    stroke="var(--content-brand-primary-base)"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          )
        ) : (
          ""
        )}
        {/* {!BrowserIsDevice || state.showMoreClientDetailsContent ? */}
        <div className="mt-4">
          <div className="row gy-3 gx-0">{profileCardOverviewContent()}</div>
        </div>
        {/* : ''} */}
      </div>
    );
  };

  const overviewContentCard = () => {
    return (
      <RAFEntityProvider moduleName={CareEsioEntity.CareRecipient.EntityName}>
        <RAFAttributeRelatedListProvider
          moduleName={CareEsioEntity.CareRecipient.EntityName}
        >
          <SummarySectionFields
            contextModuleName={CareEsioEntity.CareRecipient.EntityName}
            contextObjectUID={currentObject.UID}
            removePadding
            type="Details"
            profileCard
          />
        </RAFAttributeRelatedListProvider>
      </RAFEntityProvider>
    );
  };

  // let today = setZeroHours(moment().toDate());
  // let age = CalendarUnit.YEARS.between(start, end);
  // console.log("today", Years.yearsBetween(birthdate, now);)

  // const onToggleVisiblity = (toggleVisibility?: string) => {
  //   setToggleVisiblity(toggleVisibility);
  //   if (isNotNullAndUndefined(props.onMoreClicked)) {
  //     props.onMoreClicked(toggleVisibility);
  //   }
  // };

  const getFollowActionButton = useCallback(() => {
    return (
      <RAFFollowButton
        //objectUID={rafObject.objectId}
        objectUID={currentObject.UID}
        entityName={CareEsioEntity.CareRecipient.EntityName}
        className="profile-top-followBtn btn-roundedCorner"
      />
    );
  }, []);

  //show more client details start
  const showMoreClientDetailsContent = () => {
    setState({ showMoreClientDetailsContent: true });
    // setSelectedRecipientRightContent(CareEsioEntity.CareRecipient.EntityName);
  };

  const onSelectedRecipientRightContent = (item: string) => {
    if (isNotNullAndUndefined(item)) {
      setSelectedRecipientRightContent(item);
    } else {
      setSelectedRecipientRightContent(null);
      closeMoreClientDetailsDialog();
    }
  };

  const moreClientDetailsDialogContent = () => {
    if (state.showMoreClientDetailsContent) {
      return (
        <div className="p-2 p-md-3 h-100">
          <div className="section__container">
            <div
              className={`section__div section__div__maxHeight customScrollBar`}
            >
              {/* <CareRecipientProfileInfoCard /> */}
              <AboutClient
                selectedTab={CareEsioEntity.CareServiceContract.EntityName}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const closeMoreClientDetailsDialog = async () => {
    setState({ showMoreClientDetailsContent: false });
  };
  if (props.cardStyle === "SimpleCard") {
    return (
      <CustomCardWidget
        onClick={() =>
          navigate(`/RAF/View/care_recipient/${currentObject.UID}`)
        }
        cardContentClassName="p-2 p-md-3"
      >
        <div className="row gx-2 align-items-center">
          <div className="col-auto">
            {profilePicContent(
              "profileImg-container-xmd",
              false,
              "3rem",
              "1rem"
            )}
          </div>
          <div className="col">
            <div className="subtitle_2 semi_bold mb-1">{careRecipientName}</div>
            <div className="row gx-2">
              <div className="col-auto">{clientStatusBadge("raf_20")}</div>
              <div className="col-auto">{clientDOBBadge("raf_20")}</div>
            </div>
          </div>
          <div className="col-auto">
            <RAFButtonComponent
              btnContent="View Details"
              iconCss="fas fa-arrow-right-long fa-md pe-2 content_brand_primary_base"
              onClick={() =>
                onSelectedRecipientRightContent(
                  CareEsioEntity.CarePlan.EntityName
                )
              }
              className="bg-transparent border-0"
              iconBtn
            ></RAFButtonComponent>
          </div>
        </div>
      </CustomCardWidget>
    );
  }

  const onclickRecipientCard = (page: "go_to_profile" | "go_to_page") => {
    if (page === "go_to_profile") {
      onSelectedRecipientRightContent(CareEsioEntity.CarePlan.EntityName);
    } else {
      navigate(`/RAF/View/care_recipient/${currentObject.UID}`);
    }
  };

  if (props.cardStyle === "SimpleContent") {
    return (
      // <CustomCardWidget
      //   onClick={() =>
      //     navigate(`/RAF/View/care_recipient/${currentObject.UID}`)
      //   }
      //   cardContentClassName="p-2 p-md-3"
      // >
      <div className="row gx-2 align-items-center">
        <div className="col-auto">
          {profilePicContent("profileImg-container-xmd", false, "3rem", "1rem")}
        </div>
        <div className="col">
          <div className="subtitle_2 semi_bold mb-1">{careRecipientName}</div>
          <div className="row gx-2">
            <div className="col-auto">{clientStatusBadge("raf_20")}</div>
            <div className="col-auto">{clientDOBBadge("raf_20")}</div>
          </div>
        </div>
        {props.isdashboard === true ? (
          <div className="col-auto">
            <RAFButtonComponent
              btnContent="View Details"
              iconCss="fas fa-arrow-right-long fa-md"
              onClick={() => onclickRecipientCard("go_to_page")}
              className="semi_dark btn_brand_primary e-round"
              iconBtn
            ></RAFButtonComponent>
          </div>
        ) : (
          ""
        )}
      </div>
      // </CustomCardWidget>
    );
  } else if (props.cardStyle === "PhoneEmailAddressContent") {
    return profileCardPhoneEmailAddressContent();
  }
  //show more client details end
  return (
    <>
      {BrowserIsDevice ? (
        <>
          <div
            key={props.selectedTabItem.value}
            id={`profile_left_card_${CareEsioEntity.CareRecipient.EntityName}`}
          ></div>
          <div className="profile-top-header">
            {/* {getFollowActionButton()} */}
          </div>
          <CustomCardWidget
            // cardContentClassName="px-0 pt-3 py-2 py-md-3"
            cardClassName="h-100"
            cardContentClassName="p-2 p-md-3"
          >
            <div className="row gx-0 gy-2 align-items-center">
              <div className="col-12">{profileCard()}</div>
              {/* 
                <div className="col-auto">
                  <RAFButtonComponent
                    isPrimary
                    action={RAFButtonConstant.ShowMore}
                    btnContent="Show More"
                    onClick={showMoreClientDetailsContent}
                    className={`e-outline btn-dashed btn-max-width w-100 btn-roundedCorner`}
                  ></RAFButtonComponent>
                </div> */}
              {/* <div className="col-12 border-bottom"></div>
                <div className="col-12 mt-2">{overviewContentCard()}</div>
                <div className="col-12 border-bottom"></div> */}
            </div>
          </CustomCardWidget>
          {false && (
            <div className="row gx-0 gy-2">
              <div className="col-12">
                <div className="custom__card">
                  <div className="custom__card__content p-0">
                    {profileCard()}
                  </div>
                  <div className="custom__card__content p-3 pt-2">
                    {overviewContentCard()}
                  </div>
                </div>
              </div>
              {/* <div className="col-12">
                              <div className="custom__card">
                                  <div className="custom__card__content p-3 pt-2">
                                      {overviewContentCard()}
                                  </div>
                              </div>
                          </div> */}
            </div>
          )}
        </>
      ) : (
        <div className="profile_card_new" id={"careRecipientProfileInfoDiv"}>
          <div
            className="h-100 hidden"
            id={`profile_left_card_${CareEsioEntity.CareRecipient.EntityName}`}
            key={`${props.selectedTabItem.value}_${props.selectedRecipientRightContent}`}
          ></div>
          <div className="profile-top-header"></div>
          <div className="p-3">
            {profileCard()}

            {/* <div className="mt-4">{alertsAndWarningsContent()}</div> */}
            {/* <CareProfileContentDetails
              careRecipientUID={props.careRecipientUID}
              currentObject={currentObject}
            /> */}
          </div>
        </div>
      )}
      {/* {state.showMoreClientDetailsContent && (
        <DialogComponent
          header={"Details"}
          showCloseIcon
          visible={state.showMoreClientDetailsContent}
          cssClass="rightDialog rightDialog-lg createEditForm full-height form-center-dialog dlg-new-style"
          id="client_details_dialog"
          content={moreClientDetailsDialogContent.bind(this)}
          isModal
          target="body"
          closeOnEscape={false}
          close={closeMoreClientDetailsDialog.bind(this)}
          open={PreventFocusOnDialogOpen}
          zIndex={1200}
          ref={clientDetailsDialogRef}
        />
      )} */}
    </>
  );
}

export default React.memo(CareRecipientProfileInformation);

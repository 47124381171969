import moment from "moment";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import {
  isNotEmptyArray,
  isNotNullAndUndefined,
  setZeroHours,
} from "../../../RAFComponents/helpers/utils";
import { ListServiceRequest } from "../../../RAFComponents/models/Common/ListRequest";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import { ContentType } from "../../../constants/Common/Constants";
import {
  DateRangeTypes,
  ScheduleFilterRow,
} from "../../ActiveContacts/ServiceTransaction/ServiceTransactionHelper";
import { ShiftActivityTitle } from "../CareShiftLog/CareShiftLogHelper";

export const getSupportLogRelatedRecordsListServiceRequest = (
  selectedEntity: string,
  care_recipientuid: string,
  scheduleFilterRow?: ScheduleFilterRow
) => {
  let customFilter: RAFCustomFilter = {};
  customFilter.Condition = "and";
  customFilter.Rules = [];

  const scheduleFilter = convertSupportLogRowRAFCustomFilter(
    scheduleFilterRow,
    care_recipientuid
  );
  if (isNotEmptyArray(scheduleFilter)) {
    scheduleFilter.forEach((filter) => {
      customFilter.Rules.push(filter);
    });
  }

  const listRequest: ListServiceRequest = {
    Skip: 0,
    Take: 1000,
    ViewName: `all_${selectedEntity}`,
    CustomFilter: customFilter,
  };

  let sortBy = {
    field: "start_date",
    order: "ascending",
  };
  let sortQuery: string[] = [];
  if (isNotNullAndUndefined(sortBy) && isNotNullAndUndefined(sortBy.field)) {
    sortQuery.push(
      sortBy.order === "descending" ? `${sortBy.field} desc` : sortBy.field
    );
  }
  listRequest.Sort = sortQuery;

  listRequest.EntityName = selectedEntity;

  return listRequest;
};

export const getSupportLogRelatedRecords = (
  selectedEntity?: string,
  care_recipientuid?: string,
  scheduleFilterRow?: ScheduleFilterRow
) => {
  return new Promise<any>((resolve) => {
    let url = `DataList/List`;

    const listRequest: ListServiceRequest =
      getSupportLogRelatedRecordsListServiceRequest(
        selectedEntity,
        care_recipientuid,
        scheduleFilterRow
      );

    return repositoryActions
      .postDataAndGetResponse(
        url,
        listRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (isNotNullAndUndefined(response)) {
          if (response.status === 204) {
            resolve(null);
          } else if (isNotNullAndUndefined(response.data)) {
            if (isNotNullAndUndefined(response.data.Entity)) {
              resolve(response.data.Entity);
            } else if (isNotEmptyArray(response.data.ResultTable)) {
              resolve(response.data.ResultTable);
            } else if (isNotNullAndUndefined(response.data.Entities)) {
              resolve(response.data.Entities);
            } else {
              resolve(null);
            }
          }
        } else {
          resolve(null);
        }
      });
  });
};

export const convertSupportLogRowRAFCustomFilter = (
  scheduleFilterRow: ScheduleFilterRow,
  realtedCareRecipientUID: string
): RAFCustomFilter[] => {
  let relatedToFilters: RAFCustomFilter[] = [];
  const filterValues: ScheduleFilterRow = { ...scheduleFilterRow };

  if (isNotNullAndUndefined(filterValues)) {
    const dateRage = filterValues.DateRange;

    const startDateTimeValue = filterValues.StartDateTimeValue;
    const endDateTimeValue = filterValues.EndDateTimeValue;

    const startDate = setZeroHours(filterValues.StartDate);
    const endDate = setZeroHours(filterValues.EndDate);

    const assigneeuid = filterValues.AssigneeUID;
    const care_recipientuid = isNotNullAndUndefined(realtedCareRecipientUID)
      ? realtedCareRecipientUID
      : filterValues.CareRecipientUID;

    const selectedActivityFormType = filterValues.ActivityFormType;

    delete filterValues.RelatedToType;
    delete filterValues.DateRange;
    delete filterValues.Assignee;
    delete filterValues.AssigneeUID;
    delete filterValues.RelatedTo;
    delete filterValues.StartDate;
    delete filterValues.EndDate;
    delete filterValues.SelectedDate;
    delete filterValues.Entity;
    delete filterValues.Status;
    delete filterValues.StartDateTimeValue;
    delete filterValues.EndDateTimeValue;
    delete filterValues.ActivityFormType;
    delete filterValues.CareRecipient;
    delete filterValues.CareRecipientUID;
    delete filterValues.ShiftType;

    if (isNotNullAndUndefined(assigneeuid)) {
      let assigneeFilter: RAFCustomFilter = {};
      let assigneeFilterVal: string[] = [];
      assigneeFilterVal.push(assigneeuid);
      assigneeFilter.Operator = RAFCustomOperator.Equal;
      assigneeFilter.Value = assigneeFilterVal;
      assigneeFilter.Field = "assigneeuid";
      relatedToFilters.push(assigneeFilter);
    }

    if (isNotNullAndUndefined(care_recipientuid)) {
      let careRecipientFilter: RAFCustomFilter = {};
      let careRecipientFilterVal: string[] = [];
      careRecipientFilterVal.push(care_recipientuid);
      careRecipientFilter.Operator = RAFCustomOperator.Equal;
      careRecipientFilter.Value = careRecipientFilterVal;
      careRecipientFilter.Field = "care_recipientuid";
      relatedToFilters.push(careRecipientFilter);
    }

    if (isNotEmptyArray(selectedActivityFormType)) {
      let relatedFilter: RAFCustomFilter = {};
      relatedFilter.Condition = "or";
      relatedFilter.Rules = [];

      selectedActivityFormType.forEach((activityFormType) => {
        if (activityFormType === ShiftActivityTitle.StartedShift) {
          const startedShiftFilter: RAFCustomFilter = {};
          startedShiftFilter.Field = "Title";
          startedShiftFilter.Operator = RAFCustomOperator.Equal;
          startedShiftFilter.Value = [ShiftActivityTitle.StartedShift];
          relatedFilter.Rules.push(startedShiftFilter);
        }
        //  else if (activityFormType === CareEsioEntity.CareShiftActivity.EntityName) {
        //   const startedShiftFilter: RAFCustomFilter = {};
        //   startedShiftFilter.Field = "Title";
        //   startedShiftFilter.Operator = RAFCustomOperator.NotEqual;
        //   startedShiftFilter.Value = [ShiftActivityTitle.StartedShift];
        //   relatedFilter.Rules.push(startedShiftFilter);
        // }
        else {
          const startedShiftFilter: RAFCustomFilter = {};
          startedShiftFilter.Field = "log_type";
          startedShiftFilter.Operator = RAFCustomOperator.Equal;
          startedShiftFilter.Value = [activityFormType];
          relatedFilter.Rules.push(startedShiftFilter);
        }
      });
      relatedToFilters.push(relatedFilter);
    }

    if (
      isNotNullAndUndefined(filterValues.Status) &&
      filterValues.Status === "AllStatus"
    ) {
      delete filterValues.Status;
    }

    if (
      dateRage === DateRangeTypes.Custom &&
      isNotNullAndUndefined(startDateTimeValue) &&
      isNotNullAndUndefined(endDateTimeValue)
    ) {
      let evalue1Filter: RAFCustomFilter = {};
      let evalue1FilterVal: string[] = [];
      evalue1FilterVal.push(startDateTimeValue as any); //28/12/2023 post  -- 27/12/2023/18:30:00 somthing greater than equal
      evalue1Filter.Operator = RAFCustomOperator.GreaterThanOrEqual;
      evalue1Filter.Value = evalue1FilterVal;
      evalue1Filter.Field = "start_date";
      relatedToFilters.push(evalue1Filter);

      let evalue2Filter: RAFCustomFilter = {};
      let evalue2FilterVal: string[] = [];
      evalue2FilterVal.push(endDateTimeValue as any);
      evalue2Filter.Operator = RAFCustomOperator.LesserThan;
      evalue2Filter.Value = evalue2FilterVal;
      evalue2Filter.Field = "start_date";
      relatedToFilters.push(evalue2Filter);
    } else if (
      isNotNullAndUndefined(startDate) &&
      isNotNullAndUndefined(endDate)
    ) {
      let evalue1Filter: RAFCustomFilter = {};
      let evalue1FilterVal: string[] = [];
      evalue1FilterVal.push(startDate as any); //28/12/2023 post  -- 27/12/2023/18:30:00 somthing greater than equal
      evalue1Filter.Operator = RAFCustomOperator.GreaterThanOrEqual;
      evalue1Filter.Value = evalue1FilterVal;
      evalue1Filter.Field = "start_date";
      relatedToFilters.push(evalue1Filter);

      let evalue2Filter: RAFCustomFilter = {};
      let evalue2FilterVal: string[] = [];
      //if (moment(eValue1).isSame(eValue2)) {  //30/12/2023 post  -- 30/12/2023/18:30:00 somthing less than
      let nextDay = moment(endDate).add(1, "days").toDate();
      evalue2FilterVal.push(nextDay as any);
      evalue2Filter.Operator = RAFCustomOperator.LesserThan;
      // } else {
      //   evalue2FilterVal.push(eValue2 as any); //30/12/2023 post  -- 29/12/2023/18:30:00 somthing less than or equal
      //   evalue2Filter.Operator = RAFCustomOperator.LesserThanOrEqual;
      // }

      evalue2Filter.Value = evalue2FilterVal;
      evalue2Filter.Field = "start_date";
      relatedToFilters.push(evalue2Filter);
    }

    let sourceObject = filterValues;
    for (let key in sourceObject) {
      let relatedFilter: RAFCustomFilter = {};
      let relatedFilterVal: string[] = [];
      relatedFilterVal.push(sourceObject[key]);
      relatedFilter.Operator = RAFCustomOperator.Equal;
      relatedFilter.Value = relatedFilterVal;
      relatedFilter.Field = key;

      if (isNotNullAndUndefined(sourceObject[key]))
        relatedToFilters.push(relatedFilter);
    }
  }

  return relatedToFilters;
};
